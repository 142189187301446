import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  
import './ArticlesSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { newsArticles } from './articlesData'; 
import { postMeta as cybersecurityMustReturnMeta } from './posts/Post-CybersecurityMustReturn';
import { postMeta as plannedParenthoodCyberattackMeta } from './posts/Post-PlannedParenthoodCyberattack';
import { postMeta as avisDataBreachMeta } from './posts/Post-AvisDataBreach';
import { postMeta as TPRMMeta } from './posts/Post-TPRM'; 
import { postMeta as slimCDDataBreachMeta } from './posts/Post-SlimCDDataBreach'; 
import { postMeta as corporateEspionageMeta } from './posts/Post-CorporateEspionage'; 
import { postMeta as twentyThreeAndMeMeta } from './posts/Post-23andMeSettlement'; 
import { postMeta as cfoPrioritizeSecurityMeta } from './posts/Post-CFOPrioritizeSecurity';
import { postMeta as columbusRansomwareMeta } from './posts/Post-ColumbusRansomwareAttack'; // Import the metadata
import { postMeta as privateEquityCybersecurityMeta } from './posts/Post-PrivateEquityCybersecurity'; // Import the metadata
import { postMeta as hospitalsCybersecurityMeta } from './posts/Post-HospitalsCybersecurity'; // Import the metadata
import { postMeta as WMDDHDataBreachMeta } from './posts/Post-WMDDHDataBreach'; 
import { postMeta as thirdPartyPlatformsRiskMeta } from './posts/Post-ThirdPartyPlatformsRisk';
import { postMeta as applicationSecurityVulnerabilitiesMeta } from './posts/Post-ApplicationSecurityVulnerabilities'; 
import { postMeta as wellsFargoDataBreachMeta } from './posts/Post-WellsFargoDataBreach';
import { postMeta as michiganMedicineCyberattackMeta } from './posts/Post-MichiganMedicineCyberattack';
import { postMeta as tmobileDataBreachMeta } from './posts/Post-TMobileDataBreach';
import { postMeta as americanWaterWorksCyberattackMeta } from './posts/Post-AmericanWaterWorksCyberattack';
import { postMeta as fidelityDataBreachMeta } from './posts/Post-FidelityDataBreach';



const allArticles = [
  {
    ...fidelityDataBreachMeta,
    link: '/posts/fidelity-data-breach',
    isInternal: true,
  },
  {
    ...americanWaterWorksCyberattackMeta,
    link: '/posts/american-water-works-cyberattack',
    isInternal: true,
  },
  {
    ...tmobileDataBreachMeta,
    link: '/posts/tmobile-data-breach',
    isInternal: true,
  },
  {
    ...michiganMedicineCyberattackMeta,
    link: '/posts/michigan-medicine-cyberattack',
    isInternal: true,
  },
  {
    ...wellsFargoDataBreachMeta,
    link: '/posts/wells-fargo-data-breach',
    isInternal: true,
  },
  {
    ...applicationSecurityVulnerabilitiesMeta,
    link: '/posts/application-security-vulnerabilities',
    isInternal: true,
  },
  {
    ...thirdPartyPlatformsRiskMeta,
    link: '/posts/third-party-platforms-risk',
    isInternal: true,
  },
  {
    ...WMDDHDataBreachMeta,
    link: '/posts/wmddh-data-breach',
    isInternal: true,
  },
  {
    ...cfoPrioritizeSecurityMeta,
    link: '/posts/cfo-prioritize-security',
    isInternal: true,
  },
  {
    ...hospitalsCybersecurityMeta,  // Add this new article entry
    link: '/posts/hospitals-cybersecurity',
    isInternal: true,
  },
  {
    ...privateEquityCybersecurityMeta, 
    link: '/posts/private-equity-cybersecurity',
    isInternal: true,
  },
  {
    ...columbusRansomwareMeta, 
    link: '/posts/columbus-ransomware-attack',
    isInternal: true,
  },
  {
    ...twentyThreeAndMeMeta,  // New article metadata
    link: '/posts/23andMe-settlement',
    isInternal: true,
  },
  {
    ...corporateEspionageMeta, 
    link: '/posts/corporate-espionage',
    isInternal: true,
  },
  {
    ...slimCDDataBreachMeta, 
    link: '/posts/slim-cd-data-breach',
    isInternal: true, 
  },
  {
    ...plannedParenthoodCyberattackMeta,
    link: '/posts/planned-parenthood-cyberattack',
    isInternal: true,
  },
  {
    ...avisDataBreachMeta, 
    link: '/posts/avis-data-breach',
    isInternal: true, 
  },
  {
    ...cybersecurityMustReturnMeta,
    link: '/posts/cybersecurity-must-return',
    isInternal: true,
  },
  {
    ...TPRMMeta,  
    link: '/posts/tprm',
    isInternal: true, 
  },
  ...newsArticles,  // The rest of the news articles
];

function ArticlesSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [articlesPerPage, setArticlesPerPage] = useState(4);
  const navigate = useNavigate();  // Initialize useNavigate

  useEffect(() => {
    const handleResize = () => {
      setArticlesPerPage(window.innerWidth < 768 ? 1 : 4);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - articlesPerPage, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + articlesPerPage, allArticles.length - articlesPerPage));
  };

  const handleArticleClick = (article) => {
    if (article.isInternal) {
      navigate(article.link);  // Use navigate instead of history.push
    } else {
      window.open(article.link, '_blank');
    }
  };

  return (
    <section className="articles-section">
      <h2 className="articles-section-title">In the News</h2>
      <div className="articles-slider">
        {currentIndex > 0 && (
          <button className="nav-button left" onClick={handlePrev} aria-label="Previous Articles">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        <div className="articles-items-container">
          {allArticles.slice(currentIndex, currentIndex + articlesPerPage).map((article, index) => (
            <div className="articles-item" key={index} onClick={() => handleArticleClick(article)}>
              <FontAwesomeIcon icon={article.icon} className="icon" />
              <div className="articles-content">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
              </div>
            </div>
          ))}
        </div>
        {currentIndex + articlesPerPage < allArticles.length && (
          <button className="nav-button right" onClick={handleNext} aria-label="Next Articles">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        )}
      </div>
    </section>
  );
}

export default ArticlesSection;
