import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Stats from './components/Stats';
import Services from './components/Services';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import ArticlesSection from './components/ArticlesSection';
import CyberSecuritySection from './components/CyberSecuritySection';
import ConsultationSection from './components/ConsultationSection';
import LoginPage from './components/LoginPage';
import AboutPage from './components/AboutPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import PartnersPage from './components/PartnersPage';
import SupportPage from './components/SupportPage';
import ClientSupport from './components/ClientSupport';
import TermsPage from './components/TermsPage';
import CapabilitiesPage from './components/CapabilitiesPage';
import PenTestingPage from './components/PenTestingPage';
import ManagedServicesPage from './components/ManagedServicesPage';
import IncidentResponsePage from './components/IncidentResponsePage';
import CloudSecurityPage from './components/CloudSecurityPage';
import InfrastructureAuditPage from './components/InfrastructureAuditPage';
import StrategyDevelopmentPage from './components/StrategyDevelopmentPage';
import GRCCompliancePage from './components/GRCCompliancePage';
import ApplicationAssessmentPage from './components/ApplicationAssessmentPage';
import DLPPage from './components/DLPPage';
import ServiceIndustriesPage from './components/ServiceIndustriesPage';
import Flyer from './components/flyer';
import CybersecurityMustReturn from './components/posts/Post-CybersecurityMustReturn';
import PostPlannedParenthoodCyberattack from './components/posts/Post-PlannedParenthoodCyberattack';
import PostAvisDataBreach from './components/posts/Post-AvisDataBreach';
import PostTPRM from './components/posts/Post-TPRM';
import PostSlimCDDataBreach from './components/posts/Post-SlimCDDataBreach';
import PostCorporateEspionage from './components/posts/Post-CorporateEspionage';
import Post23andMeSettlement from './components/posts/Post-23andMeSettlement';
import PostCFOPrioritizeSecurity from './components/posts/Post-CFOPrioritizeSecurity'; // Import the new post
import PostColumbusRansomwareAttack from './components/posts/Post-ColumbusRansomwareAttack'; // Add this import
import PostPrivateEquityCybersecurity from './components/posts/Post-PrivateEquityCybersecurity'; // Add this import
import PostHospitalsCybersecurity from './components/posts/Post-HospitalsCybersecurity'; // Import the new article
import PostWMDDHDataBreach from './components/posts/Post-WMDDHDataBreach';
import PostThirdPartyPlatformsRisk from './components/posts/Post-ThirdPartyPlatformsRisk';
import PostApplicationSecurityVulnerabilities from './components/posts/Post-ApplicationSecurityVulnerabilities';
import PostMichiganMedicineCyberattack from './components/posts/Post-MichiganMedicineCyberattack'; // Import the new post
import PostWellsFargoDataBreach from './components/posts/Post-WellsFargoDataBreach';
import PostTMobileDataBreach from './components/posts/Post-TMobileDataBreach';
import PostAmericanWaterWorksCyberattack from './components/posts/Post-AmericanWaterWorksCyberattack';
import PostFidelityDataBreach from './components/posts/Post-FidelityDataBreach';


import '@fortawesome/fontawesome-free/css/all.min.css';

function AppContent() {
  const consultationRef = useRef(null);
  const servicesRef = useRef(null);
  const articlesSectionRef = useRef(null); // Ref for articles section
  const location = useLocation();
  const [shouldScrollToConsultation, setShouldScrollToConsultation] = useState(false);

  // Scroll handlers
  const handleScrollToArticles = () => {
    articlesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToConsultation = () => {
    consultationRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Handle scroll to consultation after redirect from /consultationRef
  useEffect(() => {
    if (shouldScrollToConsultation) {
      handleScrollToConsultation();
      setShouldScrollToConsultation(false); // Reset after scrolling
    }
  }, [shouldScrollToConsultation]);

  // Set scroll flag when navigating from /consultationRef
  useEffect(() => {
    if (location.pathname === '/consultationRef') {
      setShouldScrollToConsultation(true);
    }
  }, [location]);

  return (
    <div className="App">
      {/* Conditional Header */}
      {location.pathname !== '/flyer' && (
        <Header
          onScrollToConsultation={handleScrollToConsultation}
          onScrollToServices={handleScrollToServices}
          onScrollToArticles={handleScrollToArticles} // Pass the scroll to articles handler
        />
      )}

      <Routes>
        {/* Redirect /consultationRef to home page and scroll to consultation */}
        <Route path="/consultationRef" element={<Navigate to="/" />} />

        {/* Main Page */}
        <Route
          path="/"
          element={
            <>
              <HeroSection onScrollToConsultation={handleScrollToConsultation} />
              <Stats onScrollToConsultation={handleScrollToConsultation} />
              <CyberSecuritySection />
              <div ref={articlesSectionRef}>
                <ArticlesSection />
              </div>
              <div ref={servicesRef}>
                <Services />
              </div>
              <ConsultationSection ref={consultationRef} />
            </>
          }
        />

        {/* Internal post routes */}
        <Route
          path="/posts/cybersecurity-must-return"
          element={<CybersecurityMustReturn handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/cfo-prioritize-security"
          element={<PostCFOPrioritizeSecurity handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
  path="/posts/tmobile-data-breach"
  element={<PostTMobileDataBreach handleScrollToArticles={handleScrollToArticles} />}
/>
        <Route
  path="/posts/michigan-medicine-cyberattack"
  element={<PostMichiganMedicineCyberattack handleScrollToArticles={handleScrollToArticles} />}
/>
<Route
  path="/posts/fidelity-data-breach"
  element={<PostFidelityDataBreach handleScrollToArticles={handleScrollToArticles} />}
/>
<Route
  path="/posts/american-water-works-cyberattack"
  element={<PostAmericanWaterWorksCyberattack handleScrollToArticles={handleScrollToArticles} />}
/>

        <Route
          path="/posts/planned-parenthood-cyberattack"
          element={<PostPlannedParenthoodCyberattack handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
  path="/posts/wells-fargo-data-breach"
  element={<PostWellsFargoDataBreach handleScrollToArticles={handleScrollToArticles} />}
/>
        <Route
          path="/posts/wmddh-data-breach"
          element={<PostWMDDHDataBreach handleScrollToArticles={handleScrollToArticles} />}
        />
        
        <Route
          path="/posts/hospitals-cybersecurity"
          element={<PostHospitalsCybersecurity handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route path="/posts/application-security-vulnerabilities" 
        element={<PostApplicationSecurityVulnerabilities handleScrollToArticles={handleScrollToArticles}/>} />

        <Route
          path="/posts/columbus-ransomware-attack"
          element={<PostColumbusRansomwareAttack handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/23andme-settlement"
          element={<Post23andMeSettlement handleScrollToArticles={handleScrollToArticles} />}
        />
           <Route
        path="/posts/third-party-platforms-risk"
        element={<PostThirdPartyPlatformsRisk handleScrollToArticles={handleScrollToArticles} />}
      />
        <Route
          path="/posts/private-equity-cybersecurity"
          element={<PostPrivateEquityCybersecurity handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/avis-data-breach"
          element={<PostAvisDataBreach handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/tprm"
          element={<PostTPRM handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/corporate-espionage"
          element={<PostCorporateEspionage handleScrollToArticles={handleScrollToArticles} />}
        />
        <Route
          path="/posts/slim-cd-data-breach"
          element={<PostSlimCDDataBreach handleScrollToArticles={handleScrollToArticles} />}
        />

        {/* Other Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/clientsupport" element={<ClientSupport />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/capabilities" element={<CapabilitiesPage />} />
        <Route path="/pentesting" element={<PenTestingPage />} />
        <Route path="/managed-services" element={<ManagedServicesPage />} />
        <Route path="/incident-response" element={<IncidentResponsePage />} />
        <Route path="/cloud-security" element={<CloudSecurityPage />} />
        <Route path="/infrastructure-audit" element={<InfrastructureAuditPage />} />
        <Route path="/strategy-development" element={<StrategyDevelopmentPage />} />
        <Route path="/grc-compliance" element={<GRCCompliancePage />} />
        <Route path="/application-assessment" element={<ApplicationAssessmentPage />} />
        <Route path="/dlp" element={<DLPPage />} />
        <Route path="/service-industries" element={<ServiceIndustriesPage />} />
        <Route path="/flyer" element={<Flyer />} />
      </Routes>

      {/* Conditional Footer */}
      {location.pathname !== '/flyer' && <Footer />}
      <CookieConsent />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
